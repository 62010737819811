import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Subscribe from './Subscribe';
import reportWebVitals from './reportWebVitals';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div class="not-found">
      <div class="container">
        <h1>Oops, looks like you're lost!</h1>
        <p>The page you're looking for does not exist.</p>
        <p>Please check the URL and try again.</p>
        <div class="emoji">😢</div>
        <a href="/" class="home-link">Go back to the homepage</a>
      </div>
    </div>
  )
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<App />} />
        <Route index path="/Subscribe" element={<Subscribe />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
