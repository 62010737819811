import React from 'react';
import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import Homepage from './Homepage'
import Dashboard from './Dashboard'

function App() {
  const [currentUser, setCurrentUser] = useState();
  useEffect(() => {
    const github_token = localStorage.getItem('github_token');
    if (github_token) {
      fetchUser(github_token)
    }
  }, [])
  const fetchUser = async (github_token) => {
    const api = process.env.NODE_ENV === 'development' ? 
      'http://127.0.0.1:8001' : '';
    const response = await fetch(`${api}/api/users/${github_token}`);
    const data = await response.json();
    if (data.detail) {
      toast.error(data.detail);
    } else {
      setCurrentUser(data);
    }
  }
  return (
    <div>
      <ToastContainer />
      {currentUser ? (
        <Dashboard currentUser={currentUser} />
      ) : (
        <Homepage fetchUser={fetchUser} />
      )}
    </div>
  );
}

export default App;
