import React from 'react';
import { Paper, Grid, Col, Container, Text, Image, Button, Badge, Title } from '@mantine/core';
import { MagicWandIcon } from '@modulz/radix-icons';
import { useSpring, animated } from 'react-spring';

function Homepage(props) {
  const { fetchUser } = props;
  const styleProps = useSpring({opacity: 1, from: {opacity: 0}, delay: 200});
  const handleGithubClick = () => {
    const popupWindow = window.open(
      `https://github.com/login/oauth/authorize?client_id=d7b0029827246fa05e20&redirect_uri=https://pullrequest.help/api/authed&scope=read:user%20public_repo%20repo%20write:discussion`,
      '_blank',
      'width=480,height=640'
    );
    if (window.focus) {
      popupWindow.focus();
    }
    window.loggedin = (github_token) => {
      popupWindow.close();
      fetchUser(github_token);
      localStorage.setItem('github_token', github_token);
    };
  }
  return (
    <div>
      <Container>
        <Grid justify="space-between" align="center">
          <Col span={1}>
            <Image align="left" src="/logo192.png" p={5} fit="contain" alt="Logo" />
          </Col>
          <Col span={4}>
            <Button component="a" color="blue" size="md" rightIcon={<MagicWandIcon />} onClick={handleGithubClick}>
              Automate Code Review Now
            </Button>
          </Col>
        </Grid> 
        <Paper padding="xl" shadow="xs" style={{backgroundImage: 'linear-gradient(to right, #30525e 0%, #617D8A 50%, #10221e 100%)'}}>
          <Grid gutter="md" justify="center">
              <Col span={6} sm={12} md={6} lg={6}>
                <animated.div style={styleProps}>
                  <Title order={1} align="center" style={{ marginTop: 15, marginBottom: 30, color: '#FFFFFF' }}>
                    Automated Reviews for GitHub Pull Requests
                  </Title>
                  <Title order={4} align="center"  style={{ color: '#FFFFFF' }}>
                    Unlock the full potential of your team with automatic reviews for GitHub pull requests.
                    <br/><br/>
                    <Badge color="teal" size="lg">Automate reviews</Badge>&nbsp;
                    <Badge color="teal" size="lg">Elevate your work</Badge>&nbsp;
                    <Badge color="teal" size="lg">Transform development</Badge>&nbsp;
                  </Title>
                  <Text align="left" style={{ color: '#FFFFFF', padding: 40 }}>
                  What we offer is more than just an automated code review tool. We provide an intelligent solution that understands the complexities of code, the importance of high-quality output, and the value of your time. By taking over the repetitive, time-consuming task of reviewing codes, our software enables engineers to focus on what they do best – producing amazing codes. 
                  </Text>
                  <Col style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
                    <Button component="a" color="blue" size="lg" rightIcon={<MagicWandIcon />} onClick={handleGithubClick}>
                      Automate Code Review Now
                    </Button>
                  </Col>
                </animated.div>
              </Col>
              <Col span={6} sm={12} md={6} lg={6}>
                <animated.div style={styleProps}>
                  <Title order={5} align="left" style={{ padding: 40, color: '#FFFFFF' }}>
                    Say hello to a new era of code review automation for GitHub pull requests. The latest software automation tool is here to make code reviewing effortless and seamless. Engineers no longer have to lift a finger or pore over endless lines of code in GitHub pull requests again. This is the automated solution that they’ve been waiting for.
                  </Title>
                  <Col style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
                    <Badge color="orange" size="lg">This is not the future. This is the present.</Badge>
                  </Col>

                  <Title order={3} align="center" style={{ color: '#FFFFFF', marginTop: 40 }}>
                    Fall in love with programming all over again
                  </Title>
                  <Title order={4} align="center" style={{ color: '#FFFFFF' }}>
                    Welcome to the new normal of code reviewing
                  </Title>
                   
                </animated.div>
              </Col>
          </Grid>
        </Paper>
        <Title order={1} align="center" style={{ marginTop: 30, marginBottom: 10, color: '#FFFFFF' }}>
          Accurate Automated Reviews
        </Title>
        <Grid gutter="md" justify="center">
          <Col>
            <Image src="/screenshot1.png" p={5} fit="contain" alt="Screenshot of an automated pull request review" />
          </Col>
        </Grid>
        <Text style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 15, marginTop: 60, color: '#FFFFFF' }}>
          Made with ♥️ in San Francisco
        </Text>
      </Container>
    </div>
  );
}

export default Homepage;
