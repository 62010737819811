import React from 'react';
import { useState, useEffect } from 'react';
import { Paper, Grid, Col, Container, Image, Text, Title, Input, Button, Table } from '@mantine/core';
import { toast } from 'react-toastify';

const github_token = localStorage.getItem('github_token');
const api = process.env.NODE_ENV === 'development' ? 
  'http://127.0.0.1:8001' : '';

function Dashboard(props) {
  const { currentUser } = props
  const [prUrl, setPrUrl] = useState('');
  const [prData, setPrData] = useState([]);
  const [timeNow, setTimeNow] = useState(new Date());
  const [timeLastUpdated, setTimeLastUpdated] = useState(new Date());
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const handleSubmit = async () => {
    if (!prUrl) {
      toast.error('Please enter a Pull Request URL');
      return;
    }
    setIsButtonDisabled(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ pr_url: prUrl })
    };
    const response = await fetch(`${api}/api/users/${github_token}/create_review`, requestOptions);
    const resp = await response.json();
    if (resp['error']) {
      toast.error(resp['error']);
    } else {
      toast.success("Review has been created.");
    }
    setIsButtonDisabled(false)
  };

  useEffect(() => {
    fetchPRData();
    const intervalId = setInterval(fetchPRData, 10000);
    const intervalTimeNowId = setInterval(() => {
      setTimeNow(new Date());
    }, 1000);
    return () => {
      clearInterval(intervalId);
      clearInterval(intervalTimeNowId);
    };
  }, []);

  const fetchPRData = async () => {
    try {
      const response = await fetch(`${api}/api/users/${github_token}/prs`);
      const data = await response.json();
      setPrData(data);
      setTimeLastUpdated(new Date());;
    } catch (error) {
      toast.error('Error fetching PR data', error);
    }
  };

  const formatDateTime = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return dateTime.toLocaleString('en-US', options);
  };

  return (
    <div>
      <Container>
        <Grid justify="space-between" align="center">
          <Col span={1}>
            <Image align="left" src="/logo192.png" p={5} fit="contain" alt="Logo" />
          </Col>
          <Col span={4}></Col>
        </Grid> 
        <Paper padding="xl" shadow="xs" style={{backgroundImage: 'linear-gradient(to right, #30525e 0%, #617D8A 50%, #10221e 100%)'}}>
          <Grid gutter="md" justify="center">
            <Col span={6} sm={12} md={6} lg={6}>
              <Title order={1} align="center" style={{ marginTop: 15, marginBottom: 30, color: '#FFFFFF' }}>
                Hi {currentUser.name.split(' ')[0]}
              </Title>
            </Col>
          </Grid>
          <Grid gutter="md" justify="center">
            <Col span={6} sm={12} md={6} lg={6}>
              <Text align="left" style={{ color: '#FFFFFF', padding: 40 }}>
                Please enter a Pull Request URL for the system to create a review. The review will be created in "draft" mode so you have a chance to take a look before submitting it:
              </Text>
              <Input
                value={prUrl}
                onChange={(event) => setPrUrl(event.target.value)}
                placeholder="e.g., https://github.com/torvalds/linux/pull/780"
                style={{ marginBottom: 15 }}
              />
              {prData.length > 3 ? (
                <Button href="/subscribe" fullWidth>
                  Limit reached, please subscribe
                </Button>
              ) : (
                <Button onClick={handleSubmit} fullWidth disabled={isButtonDisabled}>
                  {isButtonDisabled ? 'Generating...' : 'Generate Review'}
                </Button>
              )}
            </Col>
          </Grid>
          <Grid justify="space-between" align="left">
            <Col>
            {prData.length > 0 && (
              <>
                <Text align="right" style={{ color: '#FFFFFF', fontSize: '10px', fontFamily: 'monospace', lineHeight: '9px' }}>
                  Time now: {timeNow.toLocaleString('en-US')}<br/>
                  Last updated: {timeLastUpdated.toLocaleString('en-US')}
                </Text>
                <Table className="review-table">
                  <thead>
                    <tr>
                      <th>PR URL</th>
                      <th>Date Created</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {prData.map((pr) => (
                      <tr key={pr.id}>
                        <td>
                          <a href={pr.url} target="_blank" rel="noopener noreferrer">
                            {pr.url}
                          </a>
                        </td>
                        <td>{formatDateTime(pr.created_at)}</td>
                        <td>{pr.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
            </Col>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
}

export default Dashboard;
