import React, { useState } from 'react';
import StripeCheckout from 'react-stripe-checkout';

const Subscribe = () => {
  const [subscription, setSubscription] = useState(null);

  const onToken = (token) => {
    console.log(token);
    // Process the token on the server-side and set the subscription flag to true
    // Redirect the user to a success page or display a success message
    setSubscription(true);
  }

  return (
    <div className="subscribe-page">
      <h1 className="title">Join our community</h1>
      <p className="description">Get a premium subscription and unlock special features</p>
      <div className="product-container">
        <div className="product">
          <h3 className="product-name">Premium Subscription</h3>
          <p className="product-description">Access to all features</p>
          <h4 className="product-price">$20 per month</h4>
          {!subscription ? (
            <StripeCheckout 
              token={onToken}
              stripeKey={process.env.REACT_APP_STRIPE_KEY}
              name="Premium Subscription"
              amount={20 * 100}
              billingAddress
              shippingAddress
              label="Subscribe"
              currency="USD"
              panelLabel="Subscribe for"
            />
          ) : (
            <div className="success-message">
              <h4 className="success-title">Thank you for subscribing!</h4>
              <p className="success-description">Your payment was successful.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Subscribe;
